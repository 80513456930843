<template>
  <div class="amend-order bsbb">
    <van-nav-bar title="改订单信息" left-arrow @click-left="onClickLeft" :border="false" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field v-model="amendMsg.phone" type="tel" name="电话" label="电话" placeholder="手机号码"
          :rules="[{ pattern, required: true, message: '请输入正确手机号码' }]" />
        <van-field v-model="amendMsg.dec" name="备注" label="备注" placeholder="备注"
          :rules="[{ min: 1, required: true, message: '请填写备注' }]" />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button :disabled="disabled" round block :type="type" native-type="submit" v-if="change">
          确认修改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amendMsg: {
        phone: "",
        dec: "",
      },
      pattern: /^1[3-9]\d{9}$/,
      type: "",
      disabled: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit() {
      console.log("提交");
    },
    ex() {
      if ((this.type = "success")) {
        this.disabled = false;
      }
    },
  },
  computed: {
    change() {
      if (this.amendMsg.phone != "" && this.amendMsg.dec != "") {
        return (this.type = "success");
      } else {
        return (this.type = "default"), (this.disabled = true);
      }
    },
  },
  created() {
    this.ex();
  },
};
</script>

<style lang="scss" scoped>
.amend-order {
  padding: 70px 30px;

  .van-form {
    margin-top: 30px;

    .van-field {
      padding: 30px;
    }

    .van-button {
      padding: 30px;
    }
  }
}
</style>